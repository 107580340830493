@import "../../styles/variables";

.header {
  padding: 0 1rem;
  box-shadow: 1px 1px 3px $color-primary-1;
  nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    ul {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      a {
        color: $color-primary-2;
      }
    }
    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      aside {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3%;
        span {
          display: inline;
          font-size: 1rem;
          font-weight: 100;
          img {
            width: 2.5em;
            height: 2.5em;
            border-radius: 50%;
            border: 1px solid $text-color-black;
            background: #555;
          }
        }
        @media screen and (max-width: $screen-xs-mb) {
          display: none;
        }
      }
    }
  }

  &.darkMode {
    background: $color-primary-1;
    ul {
      a {
        color: $color-secondary-1 !important;
      }
    }
  }
}
