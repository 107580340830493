@import "../../styles/variables";

.MyProfilePage {
  display: flex;
  flex-direction: column;
  justify-content: center;

  div {
    border-top: 1px solid $text-color-black;
    border-bottom: 1px solid $text-color-black;
    padding: 2rem 0;
  }

  &__SectionA {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
      width: 10em;
      height: 10em;
      border-radius: 50%;
      border: 1px solid $text-color-black;
      background: $color-primary-1;
      padding: 0.1em;
    }
    h4,
    h5 {
      color: $color-primary-1;
      font-style: oblique;
      font-weight: 500;
    }

    h6 {
      color: $color-secondary-1;
      font-style: italic;
    }
  }

  &__SectionB {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    h4 {
      span {
        font-size: 0.6rem;
      }
    }
    p {
      font-family: $font-family-secondary;
      color: $color-secondary-1;
      font-weight: 800;
    }
  }

  &__SectionC {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
    span {
      font-family: $font-family-secondary;
      color: $color-secondary-1;
      font-weight: 800;
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    h6 {
      margin-top: 2rem;
      color: $color-secondary-1;
      cursor: pointer;
      border-radius: 1rem;
      box-shadow: 1px 1px 1px $color-secondary-5;
      padding: 0.4rem;
      font-size: 1rem;
    }
  }

  &.darkMode {
    background: $color-primary-2;
    h6 {
      background: $color-primary-1;
    }
    h4,
    h5 {
      color: $text-color-white;
    }
  }
}
