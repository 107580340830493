@import "../../styles/variables";

.preview {
  display: flex;
  flex-direction: column;
  &__info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
    padding: 1rem 2rem;
    @media (max-width: 30rem) {
      flex-direction: column;
      justify-content: center;
    }
    &:nth-child(even) {
      flex-direction: row-reverse;
      flex-grow: 1;
      flex-basis: 0;
      @media (max-width: 30rem) {
        flex-direction: column;
        justify-content: center;
      }
    }
    div:first-child {
      flex: 5;
      p {
        font-size: 1rem;
      }
      @media (max-width: $screen-sm-tab) {
        flex: 3;
      }
    }
    div:last-child {
      flex: 1;
      img {
        width: 80%;
        height: 80%;
        object-fit: cover;
        @media (max-width: 30rem) {
          width: 100%;
          height: 100%;
        }
      }
      @media (max-width: $screen-sm-tab) {
        flex: 2;
      }
    }

    @media (max-width: $screen-xss-smadev) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &:nth-child(even) {
        flex-direction: column;
        flex-grow: 0;
      }
      img {
        width: 70%;
        height: 70%;
        object-fit: cover;
      }
    }
  }

  &__posts {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3%;
    margin: 1rem 1rem;
    // justify-items: center;
    height: 100%;
    &__popularPost {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 1rem;
      border-radius: 0.8em;
      border: 2px solid rgba(3, 6, 44, 0.95);
      width: 100%;
      @media screen and (max-width: $screen-xs-mb) {
        margin-bottom: 4rem;
      }
      &:hover {
        opacity: 0.6;
        border-radius: 0.8em;
        border: 2px solid $text-color-lightGray;
      }
      img {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 14rem;
        width: 98%;
        // aspect-ratio: 1;
        border-radius: 0.8em 0.8em 0 0;
        margin: 0.2rem;
        @media screen and (max-width: $screen-md-lap) {
          height: 12rem;
          width: 97%;
        }
        @media screen and (max-width: $screen-sm-tab) {
          height: 12rem;
          width: 98.3%;
        }
      }
      &__glass {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 1rem;
        background: rgba(255, 255, 255, 0.05);
        box-shadow: 1px 1px 2px 1px rgba(3, 6, 44, 0.95);
        backdrop-filter: blur(13px);
        -webkit-backdrop-filter: blur(13px);
        border: 1px solid rgba(3, 6, 44, 0.95);
        border-radius: 0 0 0.8em 0.8em;
        width: 100%;
        h2 {
          font-size: 1.1rem;
          margin-bottom: 0.7rem;
        }

        div {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0.7rem;
          p {
            font-size: 0.8rem !important;
          }
        }

        aside {
          p {
            font-size: 0.8rem !important;
          }
        }

        @media screen and (max-width: $screen-md-lap) {
          top: 11.2rem;
        }
        @media screen and (max-width: $screen-sm-tab) {
          top: 9.5rem;
        }
      } 
    }

    // ON &__posts || MOBILE SCREEN
    @media screen and (max-width: $screen-xs-mb) {
      grid-template-columns: repeat(1, 1fr);
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }

  &__aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 1rem 2rem;
    width: 100%;
    background: rgba(2, 3, 32, 0.048)
      url("https://ik.imagekit.io/vinnie/BLOG/blog1-01_u0BWAw78R.png?updatedAt=1684568914565")
      no-repeat center center/cover;
    &__1 {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      &__A {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        margin-top: 1rem;
        div {
          display: flex;
          gap: 0 1rem;
          align-items: flex-start;
          justify-content: center;
          img {
            width: 3rem;
            height: 3rem;
            aspect-ratio: 1;
            border-radius: 50%;
            border: 3px solid $color-primary-2;
          }
          div {
            display: flex;
            flex-direction: column;
            text-transform: uppercase;
            p {
              border-bottom: none;
              font-size: 1rem;
            }
            strong {
              font-size: 1.2rem;
            }
          }
        }
        p {
          text-align: left;
          font-size: 1rem;
          border-bottom: 1px solid $text-color-black;
          padding-bottom: 0.7rem;
          span {
            color: $color-secondary-1;
            font-style: italic;
          }
        }
      }
      &__B {
        a {
          width: 5rem;
          height: 5rem;
          aspect-ratio: 1;
          border-radius: 50%;
          color: $color-primary-2;
        }
      }
      // media queries ||  &__1
      @media (min-width: $screen-xs-mb) {
        flex-direction: row;
        justify-content: space-around;
        gap: 0 10%;

        > * {
          flex: 1;
        }
      }
    }
    // media queries ||  &__aside
    @media screen and (max-width: $screen-xs-mb) {
      text-align: left;
    }
    // darkmode on __aside
    &.darkMode {
      background: $color-secondary-5
        url("https://ik.imagekit.io/vinnie/BLOG/blog1-01_u0BWAw78R.png?updatedAt=1684568914565")
        no-repeat center center/cover;
      img {
        border: 3px solid $color-secondary-5;
      }

      a {
        color: $color-secondary-1;
      }
    }
  }
}
