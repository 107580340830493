@import "../../styles/variables";

.userBoard,
.postBoard,
.commentBoard {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  &__A {
    display: flex;
    padding: 1rem;
    gap: 1rem;
    &__1 {
      flex-basis: 30%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      aside:first-child {
        background: $text-color-white;
        padding: 0.5rem;
        margin-bottom: 0.8rem;
        border-radius: 0.8rem;
        span {
          border-radius: 10px;
          background: #ddd;
          font-size: 0.6rem;
          padding: 0.4rem;
        }
      }
      aside:last-child {
        > * {
          margin-bottom: 0.5rem;
          background: #ddd;
          padding: 0.5rem;
          border-radius: 0.8rem;
        }
      }
    }
    &__2 {
      flex-basis: 70%;
      background: $text-color-white;
      overflow: auto;
    }
  }

  &__B {
    display: flex;
    flex-direction: column;
    gap: 5%;
    min-width: 100%;
    justify-content: space-between;
    align-items: flex-start;

    &__1,
    &__2 {
      margin: 0.5rem 0;
      background-color: $text-color-white;
      padding: 1rem;
      border-radius: 0.8rem 0.4rem;
      width: 100%;
      div {
        display: flex;
        flex-direction: column;
        padding: 1rem 0;
        // h5 {

        // }
        span {
          color: $color-secondary-4;
          font-size: 1em;
          font-family: $font-family-tertiary;
        }
      }
    }
  }

  &__C {
    max-height: 30rem;
    overflow: auto;
    max-width: 70vw;
    margin: 2rem 0;
  }

  // media query for smaller screen || .userBoard
  @media screen and (max-width: $screen-xss-smadev) {
    opacity: 1 !important;
    &__A,
    &__B {
      &__1 {
        flex-basis: 30%;
        max-width: 90%;
      }
      &__2 {
        flex-basis: 30%;
        max-width: 90%;
      }
    }

    &__B {
      width: 30%;
    }

    &__C {
      max-width: 55vw;
    }
  }

  // media query for medium mobile screen || .userBoard
  @media screen and (max-width: 42rem) {
    &__A,
    &__B {
      flex-direction: column;
      &__1 {
        flex-basis: 50%;
      }
      &__2 {
        flex-basis: 50%;
      }
    }

    &__B {
      width: 50%;
    }

    &__C {
      max-width: 55vw;
    }
  }
  // media query for larger screen || .userBoard
  @media screen and (min-width: $screen-md-lap) {
    &__A {
      &__1 {
        aside:last-child {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 1rem;
        }
      }
    }
    &__B {
      flex-direction: row;
    }
    &__C {
      max-width: 80vw;
    }
  }

  &.darkMode {
    background-color: $color-primary-1 !important;
    h4,
    aside,
    span {
      background-color: $color-primary-2 !important;
    }
    div {
      background-color: $color-primary-2 !important;
    }
  }
}

// TABLES STYLING //
.search {
  margin: 5px 0;
  position: relative;
  flex: 1;

  .icon {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
  }

  input[type="text"] {
    display: block;
    font-size: 1.6rem;
    font-weight: 300;
    padding: 1rem;
    padding-left: 3rem;
    margin: 1rem auto;
    width: 100%;
    border: 1px solid #777;
    border-radius: 3px;
    outline: none;
  }
}

.userTable,
.postTable,
.commentTable {
  border-collapse: collapse;
  width: 100%;
  background: $text-color-white;
  position: relative;
  th,
  td {
    text-align: center;
    padding: 0.4rem 0.3rem;
    border: 1px solid #ddd;
  }
  thead {
    position: sticky;
    top: 0;
    left: 0;

    tr {
      word-wrap: normal;
      word-break: keep-all;
      th {
        position: sticky;
        padding: 1rem 1rem;
        text-align: left;
        background-color: $color-primary-1;
        color: $text-color-white;
      }
    }
  }

  tbody {
    tr {
      overflow-y: scroll;
      td {
        img {
          width: 2.2em;
          height: 2.2em;
          border-radius: 50%;
          border: 1px solid $text-color-black;
          padding: 0.2em;
        }
        form {
          display: flex;
          gap: 5%;
          select {
            background: rgba(139, 143, 219, 0.219);
            color: $color-primary-1;
            width: 6rem;
            border-radius: 0.3rem;
            border: none;
            outline: none;
            padding: 0.3rem 0.1rem;
            font-size: 0.7rem;
            font-weight: 900;
          }
        }
      }
    }
  }
  &.darkMode {
    tbody {
      tr {
        td {
          background-color: $color-primary-3;
        }
      }
    }
  }
}
