
@import "../../../styles/variables";

.BlogPreview {
  margin: 2rem;
  button {
    padding: .9rem;
    margin-bottom: 1rem;
  }

  aside {
    margin: 1.5rem 0;
  }

  form {
    display: flex;
    align-items: flex-start; 
    margin-bottom: 1rem;

    select {
      margin-right: 1rem; 
      padding: 0.5rem; 
    }

    button {
      padding: 0.5rem;
      background-color: $color-primary-2; 
      color: $text-color-white; 
      border: none; 
      cursor: pointer; 
      transition: background-color 0.3s ease; 
    }

    button:hover {
      background-color: $color-primary-2; 
    }
  }


}
