@import "../../styles/variables";

.blog {
  margin: 2rem;
  display: flex;
  flex-direction: column;
  .activeLink {
    text-decoration: underline;
    color: $color-primary-1;
  }

  // navigation section
  aside {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 10%;
    width: auto;
    box-shadow: 1px 1px 3px $color-primary-1;
    padding: 1rem 0;

    .search {
      input {
        font-size: .8rem;
        font-weight: 800;
        padding: 0.5rem 0.3rem;
        margin: 1rem auto;
        width: 100%;
        border: 1px solid #777;
        border-radius: 3px;
        outline: none;
        color: $text-color-white !important;
        background-color: $color-primary-2;
      }

      &::placeholder {
        color: $text-color-white !important;
      }
    }
  }

  // MAIN BLOG SECTION
  &__mainblog {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3%;
    margin: 2rem 0;
    min-height: 150%;
    @media (max-width: $screen-sm-tab) { //768
      margin-bottom: 22rem;
    }
     @media (max-width: $screen-xs-mb) { //576
      margin-bottom: 24.9rem;
    }
    @media (max-width: 480px) {
      margin-bottom: 25.9rem;
    }
    @media (max-width: 400px) {
      margin-bottom: 24.9rem;
    }

    &__container {
      display: flex;
      flex-direction: column;
      position: relative;
      &__top {
        &:hover {
          opacity: 0.6;
        }
        img {
          height: 18rem;
          width: 100%;
          @media screen and (max-width: $screen-md-lap) {
            height: 16rem;
          }
          @media screen and (max-width: $screen-sm-tab) {
            height: 14rem;
          }
        }
        &__glass {
          display: flex;
          justify-content: space-between;
          padding: 1rem;
          background: rgba(255, 255, 255, 0.135);
          box-shadow: 0 2px -1px 0 rgba(3, 6, 44, 0.331);
          backdrop-filter: blur(7px);
          -webkit-backdrop-filter: blur(7px);
          border: 1px solid rgba(255, 255, 255, 0.18);
          position: absolute;
          top: 14.8rem;
          left: 0;
          width: 100%;
          @media screen and (max-width: $screen-md-lap) {
            top: 12.5rem;
          }
          @media screen and (max-width: $screen-sm-tab) {
            top: 10.5rem;
          }
          div {
            p,
            span {
              font-size: 0.9rem;
              font-weight: 600;
              color: $text-color-white;
            }
          }
        }
      }

      &__bottom {
        h2 {
          font-size: 1.3rem;
          height: 4rem;
          font-weight: 700;
          margin-top: 0.6rem;
          text-transform: uppercase;
          @media screen and (max-width: $screen-sm-tab) {
            height: auto;
          }
        }
        p {
          font-size: 1rem;
          margin: 0.4rem 0;
        }
        &__B {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8rem;
            font-weight: 300;
            border-radius: 10rem;
            border: 1px solid $color-secondary-4;
            padding: 0.4rem 0.6rem;
            &:hover {
              background: $color-secondary-3;
            }
            span {
              display: flex;
              align-items: center;
              margin-left: 8px;
            }
          }
          div {
            flex-direction: column;
            align-items: flex-flex-start;
            p {
              margin: 0.1rem 0;
            }
            li {
              display: flex;
              gap: 0.5rem;
              font-size: 0.6rem;
              span {
                background-color: $color-secondary-3;
                font-weight: 300;
                border: 1px solid $text-color-black;
                padding: 0.2rem;
                border-radius: 0.3rem;
                // color: $text-color-lightGray;
              }
            }
          }
        }
      }
    }

    // @media query on || &__mainblog
    @media (max-width: $screen-sm-tab) {
      grid-template-columns: 1fr;
    }
  }

  // MISCELLANEOUS BLOG SECTION || RECENT AND POULAR ARTICLES
  // SECTION A || RECENT AND POULAR ARTICLES
  // SECTION A || RECENT AND POULAR ARTICLES
  &__miscblog {
    display: flex;
    justify-content: space-between;
    gap: 3%;
    margin-top: 7rem;


    // section A || recent articles
    &__recent {
      margin: 1rem 0;

      flex: 1;
      &:hover {
        opacity: 0.9;
      }
      img {
        margin-top: 1rem;
        height: 20rem;
        width: 100%;
        @media screen and (max-width: $screen-md-lap) {
          height: 14rem;
        }
        @media screen and (max-width: $screen-sm-tab) {
          height: 12rem;
        }
      }
      h2 {
        font-size: 1.2rem;
        font-weight: 800;
        margin-top: 0.6rem;
      }
      p,
      span {
        font-size: 0.8rem;
        margin: 0.4rem 0;
      }
    }

    // SECTION B || POPULAR ARTICLES
    // SECTION B || POPULAR ARTICLES
    &__popular {
      flex: 1;
      margin: 1rem 0;
      &__container {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        gap: 3%;
        &__Aside {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          gap: 3%;
          box-shadow: 1px 1px 3px $color-primary-1;
          margin-bottom: 1rem;
          border-radius: 0.5rem;
          &:hover {
            opacity: 0.9;
          }
          @media screen and (max-width: $screen-md-lap) and (min-width: $screen-sm-tab) {
            flex-direction: column;
          }
          img {
            height: 8rem;
            width: 100%;
            flex: 1;
            border-radius: 0.5rem;
            @media screen and (min-width: $screen-md-lap) {
              height: 13rem;
              width: 4rem;
            }
            @media screen and (max-width: 720px) {
              min-height: 13rem;
              width: 4rem;
            }
          }
          div {
            flex: 1;
            span {
              font-size: 0.6rem;
              font-weight: 700;
              margin: 0.4rem 0;
            }
            h2 {
              font-size: 1rem;
              font-weight: 800;
              margin: 0.4rem 0;
            }
            p {
              font-size: 0.8rem;
            }
            @media screen and (max-width: $screen-md-lap) and (min-width: $screen-sm-tab) {
              padding: 0.5rem;
            }
          }
        }
      }
    }

    // @media query on &__miscblog
    @media (max-width: $screen-sm-tab) {
      flex-direction: column;
      margin-top: 9rem;
    }
  }

  // @media query .blog
  @media (max-width: $screen-sm-tab) {
    aside {
      flex-direction: column;
      .search {
        input {
          width: 100%;
        }
      }
    }
  }

  // DARKMODE || .blog
  &.darkMode {
    aside {
      box-shadow: 1px 1px 3px $color-secondary-1;
    }
    .blog__mainblog__container__top__glass {
      div {
        p,
        span {
          color: $text-color-black;
          font-weight: 800;
        }
      }
    }
    .blog__mainblog__container__bottom__B {
      a {
        background: $color-secondary-3;
        border: 1px solid $color-secondary-1;
        &:hover {
          background: $color-secondary-1;
        }
      }
    }
  }
}
