@import "../../styles/variables";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  margin: 1rem auto;
  z-index: 3;
  width: 100%;

  .sideLeft {
    display: flex;
    justify-content: space-around;
    gap: 3%;
    align-items: center;
    div {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3%;
        span {
          display: inline;
          font-size: 1rem;
          font-weight: 100;
          img {
            width: 3em;
            height: 3em;
            border-radius: 50%;
            border: 1px solid $text-color-black;
            background: #555;
            padding: 0.5em;
          }
        }
      }
    }
    nav {
      margin-left: 1rem;
      display: none;
      ul {
        display: flex;
        justify-content: space-around;
        align-items: center;
        gap: 5%;
        font-size: 1rem;
        a {
          color: $color-primary-1;
          text-transform: uppercase;
          &:hover {
            background-color: $color-secondary-5;
          }
        }
      }
    }
  }
  .sideRight {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;

    nav {
      button {
        text-align: center;
        font-size: 0.7rem;
        border: 1px solid $text-color-black;
        padding: 0.4rem;
        background-color: $color-secondary-2;
        font-weight: 700;
        &:hover {
          background-color: $color-secondary-1;
        }
      }
      .sideRight__authNav {
        white-space: nowrap;
        text-decoration: none;
        ul {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8%;
          font-size: 0.8rem;
          a {
            padding: 0.4em 0.6em;
            &:nth-child(2) {
              border: $color-primary-1 solid 1px;
              transition: all 0.9s linear;
              cursor: pointer;
            }
            &:hover {
              background-color: $color-primary-1;
              color: $text-color-white;
            }
          }
        }
      }
    }
  }

  // MEDIA QUERIES || .header
  @media (min-width: $screen-sm-tab) {
    .sideLeft {
      nav {
        display: inline-block;
      }
    }
    .sideRight {
      span:last-child {
        display: none;
      }
    }
  }

  // DARK MODE SYLING || .header
  &.darkMode {
    .sideLeft {
      img {
        background: $text-color-lightGray;
      }
      strong {
        color: $text-color-lightGray;
      }
      nav {
        ul {
          a {
            color: $color-secondary-1 !important;
            box-shadow: 0 -1px 1px 1px $color-secondary-2 !important;
          }
        }
      }
    }
    .sideRight {
      nav {
        button {
          border: 1px solid $text-color-black;
          background-color: $color-secondary-1;
          &:hover {
            background-color: $color-secondary-3;
          }
        }
      }
    }
  }
}
