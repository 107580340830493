@import "../../styles/variables";

.services {
  padding: 1rem 2rem;
  h2 {
    text-align: center;
    margin-bottom: 1rem;
  }
  section {
    display: grid;
    grid-template-columns: repeat(1, minmax(300px, 2fr));
    gap: 1.5rem 0;
    place-items: center;
    div {
      padding: 1.5rem;
      border-radius: 5px;
      box-shadow: 0px 1px 5px $text-color-darkGray;
      height: 100%;
      h6 {
        font-size: 1rem;
        font-weight: 600;
        margin-bottom: 0.5rem;
      }
      p {
        font-size: .9rem;
        padding: 0.5rem 0;
      }
      p:last-child {
        box-shadow: 0px 1px 5px $text-color-darkGray;
        width: fit-content;
        padding: 0 0.2rem;
        border-radius: 5px;
        display: flex;
        background: $text-color-lightGray;
      }
      &:hover {
        background: $color-primary-1;
        color: $text-color-white;
        p:last-child {
          background: $color-primary-1;
        }
      }
    }
    // media queries ||.services
    @media (min-width: $screen-sm-tab) {
      grid-template-columns: repeat(2, minmax(300px, 2fr));
      gap: 1.5rem 1.5rem;
    }
  }

  // darkmode ||.services
  &.darkMode {
    section {
      div {
        p:last-child {
          background: $color-secondary-5;
        }
        &:hover {
          background: $color-secondary-5;
          p:last-child {
            background: $color-secondary-5;
          }
        }
      }
    }
  }
}
