@import "../../styles/variables";

.shareComponent {
  margin: 1rem;
  width: auto;
  h2 {
    font-size: 1.2rem;
    color: $text-color-white;
  }
  ul {
    display: flex !important;
    gap: 5%;
    li {
      &:hover {
        opacity: 0.7;
      }
    }
  }
  @media (max-width: $screen-xss-smadev) {
    margin: 0.8rem;
    h2 {
      font-size: 0.8rem;
    }
    ul {
      gap: 2%;
      li {
        font-size: 0.6rem !important;
      }
    }
  }
}
