@import "../../styles/variables";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  z-index: 3;
  width: 100%;
  box-shadow: 1px 1px 3px $color-primary-1;

  .sideLeft {
    display: flex;
    justify-content: space-around;
    gap: 3%;
    align-items: center;
    div {
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3%;
        span {
          display: inline;
          font-size: 1rem;
          font-weight: 100;
          img {
            width: 3em;
            height: 3em;
            border-radius: 50%;
            border: 1px solid $text-color-black;
            background: #555;
            // padding: 0.5em;
          }
        }
      }
    }
    nav {
      margin-left: 1rem;
      display: none;
      font-family: $font-family-secondary;
      ul {
        display: flex;
        justify-content: space-around;
        white-space: nowrap;
        align-items: center;
        gap: 5%;
        font-size: .8rem;
        color: $color-primary-1;
        text-transform: uppercase;
      }
    }
  }

  .sideRight {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10%;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 3%;
      span {
        display: inline;
        font-size: 1rem;
        font-weight: 100;
        img {
          width: 2.5em;
          height: 2.5em;
          border-radius: 50%;
          border: 2px solid $color-primary-2;
          background: #555;
          // padding: 0.5em;
        }
      }
      h5 {
        font-size: 0.6rem;
        white-space: nowrap;
        text-align: center;
      }
    }
  }

  // media queries
  @media (min-width: $screen-sm-tab) {
    .sideLeft {
      nav {
        display: inline-block;
      }
    }
    .sideRight {
      span:last-child {
        display: none;
      }
    }
  }

  // dark mode
  &.darkMode {
    box-shadow: 1px 1px 3px $color-secondary-1;
    .sideLeft {
      img {
        background: $text-color-lightGray;
      }
      strong {
        color: $text-color-lightGray;
      }
      nav {
        ul {
          a {
            color: $text-color-lightGray !important;
          }
        }
      }
    }
    .sideRight {
      nav {
        button {
          border: 1px solid $text-color-black;
          background-color: $color-secondary-1;
          &:hover {
            background-color: $color-secondary-3;
          }
        }
      }
    }
  }
}

// .
// .
// .
// MOBILE MENU NAVIGATION
.mobilenav {
  position: fixed;
  top: 4.5rem;
  left: 0;
  width: 100%;
  height: calc(100vh);
  border-top: 1px solid black;
  z-index: 10;
  // background-color: rgba(3, 5, 46, 0.441);
  border-radius: 1rem 1rem 0 0;
  backdrop-filter: blur(17px);
  ul {
    height: 100%;
    overflow-y: hidden !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    a {
      font-size: 1.5rem;
      font-weight: 900;
      transition: all 0.5s ease-in-out;
      -webkit-animation: blur-out-contract-bck 0.5s
        cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
      animation: blur-out-contract-bck 0.5s
        cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
      &:hover {
        opacity: 0.6;
      }
    }
  }
}

// NEWSLETTER SUBSCRIPTION SECTION

.section {
  margin: 1rem 2rem;
  display: flex;
  flex-direction: column;
  div:first-child {
    text-align: center;
    width: auto;
    h1 {
      font-size: 1.4rem;
      color: $color-primary-2;
    }
    p {
      font-size: 1rem;
      margin: 0.4rem 0;
    }
  }
  form {
    display: flex;
    gap: 5%;
    align-items: center;
    justify-content: center;
    input {
      background: rgba(139, 143, 219, 0.219);
      color: $color-primary-1;
      width: 14rem;
      box-sizing: border-box;
      border-radius: 0.4rem;
      border: none;
      outline: none;
      padding: 0.5rem 0.4rem;
      font-size: 0.7rem;
      font-weight: 900;
      transition: all 300ms ease-in-out;
    }

    button {
      width: 7rem;
      padding: 0.4rem;
      box-shadow: 1px 1px 0.2rem $color-primary-2,
        -1px -1px 0.2rem $color-primary-2;
      color: $text-color-lightGray;
      background: linear-gradient(25deg, $color-primary-1, $color-primary-3);
      font-size: 0.7rem;
      text-transform: uppercase;
      font-weight: 900;
      border-radius: 0.4rem;
      &:hover {
        background: $text-color-lightGray;
        color: $color-primary-2;
        border: none;
      }
    }

    // media queries || form
    @media (max-width: $screen-sm-tab) {
      flex-direction: column;
      align-items: center;
      // justify-content: flex-start;
      button {
        width: 14rem;
        margin-top: 1rem;
      }
    }
  }

  &.darkMode {
    div:first-child {
      h1 {
        color: $text-color-lightGray;
      }
      p {
        color: $text-color-white;
      }
    }
  }
}
