@import "./styles/variables";

.NotFound,
.blogNotFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: $color-primary-1;
  color: $text-color-white;
  padding: 2rem;
  width: 100%;
  margin: 2rem 0;

  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  p {
    font-size: 1.125rem;
    margin: 0.5rem 0;

    a {
      color: $color-secondary-1;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
