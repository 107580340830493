@import "../../styles/variables";

.dashboard {
  h3 {
    margin-bottom: 1rem;
  }
  display: flex;
  margin: 1rem;
  section {
    flex-basis: 70%;
    margin: 0 1rem;
    display: flex;
    flex-direction: column;
    // CURRENT STATS
    .dashboard__SectionA {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1rem;
      &__card {
        display: flex;
        align-items: center;
        justify-content: space-around;
        background: $text-color-white;
        padding: 0.2rem 0.4rem;
        width: 9rem;
        border-radius: 0.8rem;
        color: $color-secondary-1;
        font-size: 0.9rem;
        &.darkMode {
          background: $text-color-lightGray;
        }
        div {
          text-align: center;
          margin-right: 0.5rem;
        }
        //span for icon
        > :last-child {
          color: $color-secondary-1;
          padding: 0.4rem;
          background-color: $color-primary-1;
          display: flex;
          border-radius: 6px;
        }
      }
    }
    // USERS && POSTS GRAPH
    .dashboard__SectionB {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      gap: 1%;
      div {
        border-radius: 0.8rem;
        margin-top: 1rem;
        background: $text-color-white;
        padding: 0.8rem;
      }
    }
    // POSTS STATS
    .dashboard__SectionC {
      display: flex;
      gap: 2%;
      &__1 {
        flex-basis: 50%;
        margin: 1rem 0;
        background: $text-color-white;
        border-radius: 0.8rem;
        padding: 1rem;
        a {
          div {
            margin-bottom: 1rem;
            display: flex;
            gap: 4%;
            img {
              width: 3em;
              height: 3em;
              border-radius: 10%;
              border: 1px solid $text-color-black;
            }
            div {
              display: flex;
              flex-direction: column;
            }
          }
        }
      }
      &__2 {
        flex-basis: 50%;
        background: $text-color-white;
        border-radius: 0.8rem;
        margin: 1rem 0;
        padding: 0.8rem;
      }
    }
  }
  aside {
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    .dashboard__AsideA {
      background: $text-color-white;
      border-radius: 0.8rem;
      padding: 1rem;
      margin-bottom: 1rem;
      a {
        div {
          display: flex;
          gap: 4%;
          margin-bottom: 1rem;
          img {
            width: 3em;
            height: 3em;
            border-radius: 10%;
            border: 1px solid $text-color-black;
          }
          div {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }

    .dashboard__AsideB,
    .dashboard__AsideC {
      display: flex;
      flex-direction: column;
      background-color: $text-color-white;
      padding: 1rem;
      margin-bottom: 1rem;
      border-radius: 0.8rem;
      div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.5rem;
        p {
          display: flex;
          align-items: center;
          gap: 4%;
          img {
            width: 1.5em;
            height: 1.5em;
            border-radius: 50%;
            border: 1px solid $text-color-black;
          }
        }
      }
    }
  }

  // media query from laptop screen down || .dashboard //1264px
  @media screen and (max-width: 79rem) {
    flex-direction: column;
    section {
      .dashboard__SectionB {
        flex-direction: column;
      }
    }
  }

  // media query for mobile screen || .dashboard
  @media screen and (max-width: $screen-sm-tab) {
    section {
      .dashboard__SectionA {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 6% 0;
      }

      .dashboard__SectionC {
        flex-direction: column;
      }
    }

    aside,
    section {
      max-width: 90%;
    }
  }

  &.darkMode {
    background-color: $color-primary-1;
    div {
      background-color: $color-primary-3 !important;
    }
  }
}
