@import "../../styles/variables";

.footer {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  .footer__1 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    h5 {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
    .footer__A {
      max-width: $screen-xs-mb;
      display: flex;
      flex-direction: column;
      a {
        display: flex;
        align-items: center;
        gap: 1%;
        span {
          display: inline;
          font-size: 1rem;
          font-weight: 100;
          img {
            width: 3em;
            height: 3em;
            border-radius: 50%;
            border: 1px $color-primary-1 solid;
          }
        }
      }
      p {
        font-size: 1rem;
        margin-bottom: 0.3rem;
      }
      strong {
        margin-bottom: -0.2rem;
        font-size: 1.2rem;
      }
      ul {
        display: flex;
        gap: 1rem;
        padding: 0.1rem 0 0 0;
        font-size: 0.8rem;
        margin-bottom: 1rem;
        li {
          width: auto;
          a {
            border: 1px solid $color-primary-1;
            padding: 0.1rem;
            border-radius: 9999999px;
            &:hover {
              opacity: 0.677;
            }
          }
        }
      }
    }

    .footer__B {
      display: flex;
      flex-direction: column;
      padding-bottom: 1rem;
      ul {
        a {
          font-size: 1rem;
        }
      }
    }

    .footer__C {
      display: flex;
      flex-direction: column;
      max-width: $screen-xs-mb;
      margin-bottom: 1rem;
      strong {
        font-size: 1.2rem;
      }
      p {
        font-size: 1rem;
      }
    }

    // media queries || .footer__1
    @media (min-width: $screen-md-lap) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
      .footer__B {
        align-items: center;
        ul {
          margin-top: 1rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          a {
            font-size: 0.9rem;
          }
        }
      }
      .footer__C {
        div {
          button {
            position: relative;
            left: -1rem;
          }
        }
      }
    }
  }

  .footer__2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    a {
      display: flex;
      align-items: center;
      gap: 1%;
      span {
        display: inline;
        font-size: 1rem;
        font-weight: 100;
        img {
          width: 1.7em;
          height: 1.7em;
          border-radius: 50%;
        }
      }
    }
    p {
      font-size: 0.8rem;
    }
  }

  // DARKMODE || .footer
  &.darkMode {
    .footer__1 {
      .footer__A {
        a {
          span {
            color: $text-color-white;
            img {
              border: 2px $text-color-white solid !important;
            }
          }
        }
        ul {
          a {
            border: 1px $color-secondary-1 solid !important;
          }
        }
      }
    }
  }
}
