@import "@/app/styles/animation";
@import "@/app/styles/variables";

// mobile menu navigation
.mobilenav {
  position: fixed;
  top: 4.3rem;
  left: 0;
  width: 100%;
  height: calc(100vh);
  border-top: 1px solid black;
  z-index: 15;
  border-radius: 1rem 1rem 0 0;
  backdrop-filter: blur(17px);
  transition: opacity 4s ease-in-out;
  ul {
    height: 100%;
    overflow-y: hidden !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    text-transform: uppercase;
    a {
      font-size: 1.5rem;
      font-weight: 900;
      transition: all 0.5s ease-in-out;
      color: $color-secondary-1;
      @include animateComponent();
      -webkit-animation: blur-out-contract-bck 0.5s
        cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
      animation: blur-out-contract-bck 0.5s
        cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
      &:hover {
        opacity: 0.6;
      }
    }
  }
}
