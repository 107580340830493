@import "@/app/styles/variables" ;

.loader {
  width: 4rem;
  height: 4rem;
  display: block;
  margin: 15px auto;
  position: relative;
  color: $color-primary-1;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;

  &::after,
  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    top: 50%;
    left: 50%;
    transform: scale(0.5) translate(0, 0);
    background-color: $color-primary-1;
    border-radius: 50%;
    animation: animloader 1s infinite ease-in-out;
  }

  &::before {
    background-color: $color-secondary-1;
    transform: scale(0.5) translate(-4rem, -4rem);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animloader {
  50% {
    transform: scale(1) translate(-50%, -50%);
  }
}
