@import "../../styles/variables";
@import "../../styles/animation";

.hero {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 75%;
  position: relative;

  div:first-child {
    background-blend-mode: color;
    background: url("https://ik.imagekit.io/vinnie/BLOG/Landing%20Page/postpageview_ezs1f_Lkr.png?updatedAt=1720535038534")
      no-repeat center center/cover;
    height: 50%;
    position: relative;
    padding: 2rem;
    border-bottom-left-radius: 5% 80%;
    border-bottom-right-radius: 20% 100%;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: rgba(1, 0, 15, 0.8);
    }

    h1 {
      position: relative;
      z-index: 12;
      color: $text-color-lightGray;
      margin-bottom: 1.5rem;
    }

    p {
      position: relative;
      z-index: 2;
      color: $text-color-white;
      font-size: 1rem;
      margin-bottom: 1.5rem;
    }

    div {
      border: 1px solid $text-color-lightGray;
      width: fit-content;
      padding: 0.8rem;
      position: relative;
      z-index: 2;
      color: $text-color-lightGray;
      background: transparent;
      font-size: 1rem;
      @include animateComponent();
      -webkit-animation: heartbeat 3s ease-in-out infinite both;
      animation: heartbeat 3s ease-in-out infinite both;
      &:hover {
        border: 1px solid $text-color-darkGray;
        background: $text-color-lightGray;
        color: $text-color-darkGray;
      }
    }

    aside {
      position: absolute;
      width: 14rem;
      height: 10rem;
      right: 1rem;
      bottom: -5rem;
      z-index: 3;
      img {
        width: 100%;
        height: 100%;
        border-radius: 0.4rem 0.5rem;
      }
      @media screen and (max-width: 30rem) {
        width: 9rem;
        height: 7rem;
        bottom: -1rem;
      }
      @media screen and (max-width: 26rem) {
        width: 7rem;
        height: 5rem;
        right: 0rem;
      }
      @media screen and (max-width: 23rem) {
        width: 6rem;
        height: 4rem;
        bottom: -1.5rem;
      }
    }
  }

  // darkmode styling
  &.darkMode {
    div:first-child {
      background: radial-gradient(
          circle var(--size) at top,
          transparent,
          $color-secondary-4
        ),
        url("https://ik.imagekit.io/vinnie/Main_2k6qJNKw5O.jpg?updatedAt=1725965583392")
          no-repeat center center/cover;
      border-bottom-right-radius: 20% 40%;
      &::after {
        background: rgba(0, 0, 0, 0.915);
      }
      h1 {
        color: $color-secondary-1;
      }
      div {
        border: 1px solid $color-secondary-1;
        color: $color-secondary-1;
        &:hover {
          background: $color-secondary-1;
          color: $text-color-darkGray;
        }
      }
    }
  }
}
