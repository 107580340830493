@import "../../../styles/variables";

.subscribe {
  margin: 0.6rem 0;
  input {
    width: 12rem;
    padding: 0.5rem 2rem 0.5rem 1.2rem;
    border: none;
    outline: none;
    border-radius: 9999999px;
    font-size: 0.9rem;
    &:focus {
      outline: none;
      background: $text-color-white;
    }
    @media (max-width: $screen-md-lap) {
      width: 16rem;
      padding: 0.5rem 2rem 0.5rem 1.2rem;
    }
  }
  button {
    position: absolute;
    font-weight: 700;
    left: 10.2rem;
    padding: 0.55rem 1rem;
    border-top-left-radius: 2px;
    background: $color-primary-1;
    color: $text-color-white;
    &:hover {
      background: rgba(5, 7, 48, 0.98);
    }
    @media (max-width: $screen-md-lap) {
      left: 13.2rem;
    }
  }
}
