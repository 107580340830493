@import "./styles/variables";

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: $color-primary-1;
  color: $text-color-white;
  padding: 2rem;
  width: 100%;
  margin: 2rem 0;

  h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
    margin-bottom: 1rem;
    width: 80%;
  }

  .errorMessage {
    font-size: 1.5rem;
    color: $color-secondary-1;
    font-weight: bold;
  }

  .retryButton {
    margin-top: 1rem;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: $color-primary-1;
    background: $color-secondary-1;
    border: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: $color-secondary-2;
    }
  }
}
