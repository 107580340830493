.container {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 24px;
  max-width: 480px;
  margin: 40px auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  text-align: center;
}

.title {
  font-size: 1.75rem;
  color: #333;
  margin-bottom: 16px;
  font-weight: 600;
}

.message {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
  line-height: 1.5;
}

.note {
  font-size: 0.95rem;
  color: #777;
}

.resendLink {
  color: #007bff;
  text-decoration: none;
  font-weight: 500;
  margin-left: 5px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
