
@import "../../../styles/variables";

.createPost,
.editPost {
  width: 100%;
  background: #eee;
  padding: 2rem;
  &__details {
    h2 {
      font-size: 1rem;
      font-family: $font-family-secondary;
    }

    div {
      background-color: $text-color-white;
      border-radius: 0.6rem 0.8rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0.4rem;
    }
  }
  form {
    z-index: $z-index-3;
    box-shadow: 1px 1px 2px 1px $text-color-darkGray;
    padding: 2rem;
    margin-top: 1rem;
    .sectionA {
      div {
        display: flex;
        flex-direction: column;
        padding-bottom: 1rem;
        label {
          text-transform: uppercase;
          margin-bottom: 0.4rem;
          font-size: 0.8rem;
        }

        input,
        select {
          background: rgba(139, 143, 219, 0.219);
          color: $color-primary-1;
          width: 100%;
          box-sizing: border-box;
          border-radius: 0.8rem;
          border: none;
          outline: none;
          padding: 0.5rem 1rem;
          font-size: 1rem;
          font-weight: 900;
          transition: all 300ms ease-in-out;
        }

        input[type="file"]::file-selector-button {
          border: 1px solid rgba(139, 143, 219, 0.219);
          padding: 0.2em 0.4em;
          border-radius: 0.2em;
          background: rgba(139, 143, 219, 0.219);
          transition: 1s;
        }

        input[type="file"]::file-selector-button:hover {
          background-color: rgba(139, 143, 219, 0.746);
        }

        input {
          &:focus {
            background: linear-gradient(
              145deg,
              $color-primary-1,
              $color-primary-2
            );
            box-shadow: inset 0px 1px 2px $text-color-lightGray;
            color: $text-color-lightGray;
            font-size: $font-family-secondary;
          }
        }
      }
    }
    .sectionB {
      margin-top: 2rem;
      height: 100%;
      max-width: 250px;
    }

    button {
      width: 100%;
      margin: 1rem 0;
      padding: 0.8rem 0;
      box-shadow: 1px 1px 0.2rem $color-primary-2,
        -1px -1px 0.2rem $color-primary-2;
      color: $text-color-lightGray;
      background: linear-gradient(25deg, $color-primary-1, $color-primary-3);
      &:hover {
        background: $text-color-lightGray;
        color: $color-primary-2;
        border: none;
      }
    }
  }

  // media queries
  @media (min-width: 500px) {
    form {
      .sectionB {
        margin-top: 2rem;
        max-width: 280px;
      }
    }
  }
  // media queries
  @media (min-width: 840px) {
    form {
      .sectionA {
        display: grid;
        grid-template-columns: repeat(2, minmax(260px, 2fr));
        width: 100%;
        gap: 0 0.5rem;
      }
      .sectionB {
        margin-top: 2rem;
        max-width: 580px;
      }
    }
  }

  // media queries
  @media (min-width: $screen-md-lap) {
    form {
      .sectionA {
        grid-template-columns: repeat(3, minmax(200px, 2fr));
        width: 100%;
      }
      .sectionB {
        margin-top: 2rem;
        max-width: 100%;
      }
    }
  }
}

.bgImage {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 375px) {
  .bgImage {
    flex-direction: column;
  }
}
