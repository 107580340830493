@import "../../styles/variables";

.CTA {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background: rgba(2, 3, 32, 0.048)
    url("https://ik.imagekit.io/vinnie/BLOG/blog1-01_u0BWAw78R.png?updatedAt=1684568914565")
    no-repeat center center/cover;

  &__1 {
    margin-bottom: 1.4rem;
    h2 {
      text-align: center;
      margin-bottom: 0.4rem;
    }
    div {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 2rem;
      align-items: start;
      div {
        display: flex;
        align-items: center; 
        gap: 0.5rem;
        p {
          font-size: 1rem;
        }
        p:nth-child(1) {
          border: 1px solid $color-primary-1;
          border-radius: 50%;
          padding: 0.4rem;
        }
      }
    }
    // media queries || .CTA__1 div
    @media (min-width: $screen-sm-tab) {
      div {
        grid-template-columns: repeat(3, 1fr);
        div {
          justify-content: center;
        }
      }
    }
  }
  @media (max-width: 436px) {
    div {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
    color: $color-primary-1;
    h3 {
      font-size: 1.2rem;
      text-align: center;
      font-weight: 800;
      padding-bottom: 0.8rem;
    }
    strong {
      padding: 0.4rem 0;
      width: 20%;
      background: $color-primary-1;
      color: $text-color-white;
      &:hover {
        opacity: 0.9;
      }
    }
  }
  // darkmode || .CTA
  &.darkMode {
    background: $color-secondary-5
      url("https://ik.imagekit.io/vinnie/BLOG/blog1-01_u0BWAw78R.png?updatedAt=1684568914565")
      no-repeat center center/cover;
    h4,
    h3 {
      color: $text-color-white;
    }

    strong {
      background: $text-color-white;
      color: $color-primary-1;
      &:hover {
        background: $text-color-lightGray;
      }
    }
  }
}
