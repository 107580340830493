@import "../../styles/variables";
.emailList {
  max-height: 30rem;
  overflow: auto;
  max-width: 70vw;
  margin: 2rem 0;
  &__Table {
    border-collapse: collapse;
    width: 100%;
    background: $text-color-white;
    position: relative;
    th,
    td {
      text-align: center;
      padding: 0.4rem 0.3rem;
      border: 1px solid #ddd;
    }
    thead {
      position: sticky;
      top: 0;
      left: 0;

      tr {
        word-wrap: normal;
        word-break: keep-all;
        th {
          position: sticky;
          padding: 1rem 1rem;
          text-align: left;
          background-color: $color-primary-1;
          color: $text-color-white;
        }
      }
    }

    tbody {
      tr {
        overflow-y: scroll;
        td {
          img {
            width: 2.2em;
            height: 2.2em;
            border-radius: 50%;
            border: 1px solid $text-color-black;
            padding: 0.2em;
          }
          form {
            display: flex;
            gap: 5%;
            select {
              background: rgba(139, 143, 219, 0.219);
              color: $color-primary-1;
              width: 6rem;
              border-radius: 0.3rem;
              border: none;
              outline: none;
              padding: 0.3rem 0.1rem;
              font-size: 0.7rem;
              font-weight: 900;
            }
          }
        }
      }
    }
  }
  &.darkMode {
    background-color: $color-primary-1;
    tbody {
      tr {
        td {
          background-color: $color-primary-3;
        }
      }
    }
  }
}
